import React, { useEffect, useMemo, useState } from "react";
import Moment from "react-moment";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import {
  I_PAYMENT,
  useGetPaymentsQuery,
  useRefundPaymentMutation,
} from "app/payments/paymentsApiSlice";

import {
  createColumnHelper,
  getCoreRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { TableComponent } from "components";
import { errorHandler, getSortDetails, GlobalStrings } from "utils";
import { PaymentStatus } from "utils/enums";
import { NO_VALUE_SYMBOL } from "utils/globalStrings";

import { adminRoutesPath } from "views/admin/routes";

import styles from "../EndUser.module.scss";

const Payments = () => {
  const { userId } = useParams();
  const [refundPayment] = useRefundPaymentMutation();
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const [sorting, setSorting] = useState<SortingState>([]);
  const [refundModal, setRefundModal] = useState(false);
  const [paymentToRefund, setPaymentToRefund] = useState<I_PAYMENT>();

  const toggleRefundModal = () => setRefundModal((prev) => !prev);

  const { data, isFetching, error, refetch } = useGetPaymentsQuery({
    pageIndex,
    pageSize,
    sort: getSortDetails(sorting[0]),
    userId,
  });

  useEffect(() => {
    if (error) {
      errorHandler(error);
    }
  }, [error]);

  const columnHelper = createColumnHelper<I_PAYMENT>();

  const columns = [
    columnHelper.accessor((row) => row.id, {
      id: "id",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.ID}</span>,
      enableSorting: false,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
    }),
    columnHelper.accessor((row) => row.createdAt, {
      id: "createdAt",
      cell: (info) => (
        <p>
          <Moment date={info.getValue()} format="DD.MM.YYYY, HH:mm:ss" />
        </p>
      ),
      header: () => <span>{GlobalStrings.formLabels.date}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 20,
    }),
    columnHelper.accessor((row) => row.vehicleId, {
      id: "vehicleId",
      cell: (info) => (
        <Link
          to={adminRoutesPath.Vehicles.Vehicle.replace(":vehicleId", info.getValue().toString())}
        >
          <p>{info.getValue()}</p>
        </Link>
      ),
      header: () => <span>{GlobalStrings.formLabels.vehicleID}</span>,
      enableSorting: false,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
    }),
    columnHelper.accessor(
      (row) => {
        if (row.vehicleId.toString() === NO_VALUE_SYMBOL) {
          return row.containerBookingId;
        }
        return row.vehicleId;
      },
      {
        id: "tripId",
        cell: (info) => <p>{info.getValue()}</p>,
        header: () => <span>{GlobalStrings.partners.tripIDorBookId}</span>,
        enableSorting: false,
        enableMultiSort: true,
      }
    ),
    columnHelper.accessor((row) => row, {
      id: "amount",
      cell: (info) => (
        <p>
          {(+info.getValue().amount).toFixed(2)} {info.getValue().currency}
        </p>
      ),
      header: () => <span>{GlobalStrings.formLabels.totalPaid}</span>,
      enableSorting: false,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
    }),
    columnHelper.accessor((row) => row.status, {
      id: "status",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.status}</span>,
      enableSorting: false,
      enableMultiSort: true,
      size: 1,
      minSize: 20,
    }),
    columnHelper.accessor((row) => row.panMask, {
      id: "panMask",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.panMask}</span>,
      enableSorting: false,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
    }),
    columnHelper.accessor((row) => row, {
      id: "paymentRefund",
      cell: (info) =>
        info.getValue().status === PaymentStatus.PAYMENT_STATUS_REFUND_OK ? (
          <div className="d-flex justify-content-center gap-2">
            <Button
              className="btn btn-secondary"
              size="sm"
              disabled
              onClick={() => {
                setPaymentToRefund(info.getValue());
                setRefundModal(true);
              }}
            >
              {GlobalStrings.formLabels.refunded}
            </Button>
          </div>
        ) : (
          <div className="d-flex justify-content-center gap-2">
            <Button
              className="btn btn-primary"
              size="sm"
              onClick={() => {
                setPaymentToRefund(info.getValue());
                setRefundModal(true);
              }}
            >
              {GlobalStrings.formLabels.refund}
            </Button>
          </div>
        ),
      header: () => <span>{GlobalStrings.formLabels.refund}</span>,
      enableSorting: false,
    }),
  ];

  const table = useReactTable({
    data: data?.payments || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
      sorting,
    },
    pageCount: data?.meta?.numberOfPages,
    manualPagination: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
  });

  const refundPaymentHandler = async () => {
    try {
      await refundPayment({
        orderId: paymentToRefund?.orderId,
        amount: paymentToRefund?.amount,
      }).unwrap();
      toast.success(GlobalStrings.payments.paymentRefundedSucessfully);
      setPaymentToRefund(undefined);
      setRefundModal(false);
      refetch();
    } catch (error) {
      errorHandler(error, false);
    }
  };

  return (
    <div className={styles.payment}>
      <TableComponent
        table={table}
        tableHasData={data?.payments?.length ? data?.payments?.length > 0 : false}
        isLoading={isFetching}
      />

      <Modal isOpen={refundModal} toggle={toggleRefundModal} centered>
        <ModalHeader toggle={toggleRefundModal}>
          {GlobalStrings.payments.refundModalHeader}
        </ModalHeader>
        <ModalBody>
          <p>
            {GlobalStrings.formLabels.paymentID}: {paymentToRefund?.id}
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => refundPaymentHandler()}>
            {GlobalStrings.yes}
          </Button>
          <Button color="secondary" onClick={toggleRefundModal}>
            {GlobalStrings.no}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default Payments;
