const GlobalStrings = {
  accept: "Acceptă",
  SEO: {
    admin: {
      dashboard: "Dashboard | Ecohop Admin",
      partners: "Parteneri | Ecohop Admin",
      addPartner: "Adăugare partener | Ecohop Admin",
      addPartnerUser: "Adăugare utilizator partener | Ecohop Admin",
      editPartner: " | Partneri | Ecohop Admin",
      publicTransportPartners: "Parteneri transport în comun | Ecohop Admin",
      editPublicTransportPartner: " | Partener transport în comun | Ecohop Admin",
      addPublicTransportPartner: "Adăugare partener transport în comun | Ecohop Admin",
      accounts: "Conturi de utilizatori | Ecohop Admin",
      addAccount: "Adăugare cont utilizator | Ecohop Admin",
      globalSettings: "Setări globale | Ecohop Admin",
      maintenanceSettings: "Setări mentenanță | Ecohop Admin",
      generalAnalytics: "Date analitice generale | Ecohop Admin",
      paymentsAnalytics: "Date analitice despre plăți | Ecohop Admin",
      tripsAnalytics: "Date analitice despre călătorii | Ecohop Admin",
      heatMap: "Heatmap | Ecohop Admin",
      endUsersInvoices: "Facturi utilizatori | Ecohop Admin",
      partnersInvoices: "Facturi parteneri | Ecohop Admin",
      suppliersInvoices: "Facturi furnizori de servicii | Ecohop Admin",
      marketplace: "Administrare produse marketplace | Ecohop Admin",
      marketplaceProduct: " | Revizuire produs | Ecohop Admin",
      rechargeStationsMap: "Hartă stații de încărcare | Ecohop Admin",
      containersMap: "Hartă containere de energie | Ecohop Admin",
      rechargeStations: "Stații de încărcare | Ecohop Admin",
      containers: "Containere de energie | Ecohop Admin",
      rechargeStationsPrices: "Preturi stații de încărcare | Ecohop Admin",
      rechargeBookingHistory: "Istoric rezervări stații de încărcare | Ecohop Admin",
      container: "Container energie electrică | Ecohop Admin",
      addRechargeStation: "Adăugare stație de încărcare | Ecohop Admin",
      rechargeStation: "Stație de încărcare | Ecohop Admin",
      endUsers: "Utilizatori | Ecohop Admin",
      endUser: " | Utilizator | Ecohop Admin",
      endUsersDocuments: "Utilizatori cu documente în revizuire | Ecohop Admin",
      endUserDocuments: "Revizuire documente utilizator | Ecohop Admin",
      AIController: "AI Controller | Ecohop Admin",
      geofencing: "Geofencing | Ecohop Admin",
      vehicles: "Vehicule parteneri | Ecohop Admin",
      vehicle: "Vehicul partener | Ecohop Admin",
      createSupplierAccount: "Creare cont utilizator pentru furnizor | Ecohop Admin",
      productsSuppliers: "Furnizori de produse | Ecohop Admin",
      servicesSuppliers: "Furnizori de servicii | Ecohop Admin",
      addSupplier: "Adăugare furnizor | Ecohop Admin",
      editSupplier: " | Furnizor | Ecohop Admin",
      addSupplierService: "Adăugare serviciu furnizor | Ecohop Admin",
    },
    supplier: {
      products: "Produsele mele | Ecohop Furnizor",
      editProduct: " | Editare produs | Ecohop Furnizor",
      addProduct: "Adăugare produs | Ecohop Furnizor",
      services: "Serviciile mele | Ecohop Furnizor",
      editService: " | Editare serviciu | Ecohop Furnizor",
      addService: "Adăugare serviciu | Ecohop Furnizor",
      requestsOffers: "Solicitări de oferte primite | Ecohop Furnizor",
      declinedProducts: "Produse neacceptate în marketplace | Ecohop Furnizor",
      accounts: "Conturi de utilizatori | Ecohop Furnizor",
      addAccount: "Adăugare cont de utilizator | Ecohop Furnizor",
      vehiclesMap: "Hartă vehicule parteneri | Ecohop Furnizor",
    },
    partner: {
      vehiclesMap: "Hartă vehicule | Ecohop Partener",
      trips: "Călătorii | Ecohop Partener",
      generalData: "Informații generale | Ecohop Partener",
      prices: "Tarife | Ecohop Partener",
      vehicles: "Vechicule | Ecohop Partener",
      vehicle: "Informații vechicul | Ecohop Partener",
      editVehicle: "Editează vechicul | Ecohop Partener",
      addVehicle: "Adăugare vechicul | Ecohop Partener",
      marketplace: "Marketplace | Ecohop Partener",
      orders: "Comenzi efectuate | Ecohop Partener",
      juicers: "Juiceri | Ecohop Partener",
      carsSuppliers: "Furnizori de servicii mașini electrice | Ecohop Partener",
      bikesSuppliers: "Furnizori de servicii biciclete electrice | Ecohop Partener",
      scootersSuppliers: "Furnizori de servicii trotinete electrice | Ecohop Partener",

      requestsOffers: "Solicitări de oferte trimise | Ecohop Partener",
      requestsOffer: "Solicită ofertă | Ecohop Partener",
      accounts: "Conturi de utilizatori | Ecohop Partener",
      addAccount: "Adăugare cont de utilizator | Ecohop Partener",
      reports: "Rapoarte | Ecohop Partener",
    },
    common: {
      auth: "Autentificare | Ecohop",
      forgotPassword: "Resetare parolă | Ecohop",
      setPassword: "Schimbare parolă | Ecohop",
      notAuthorised: "Pagină restricționată | Ecohop",
      notFound: "Pagina căutată nu există sau nu ai permisiunea să o accesezi | Ecohop",
    },
    chargingPartner: {
      rechargeStationsMap: "Hartă stații de încărcare | Ecohop Partener Stații de încărcare",
      containersMap: "Hartă containere de energie | Ecohop Partener Stații de încărcare",
      rechargeStations: "Stații de încărcare | Ecohop Partener Stații de încărcare",
      containers: "Containere de energie | Ecohop Partener Stații de încărcare",
      rechargeStationsPrices: "Preturi stații de încărcare | Ecohop Partener Stații de încărcare",
      container: "Container energie electrică | Ecohop Partener Stații de încărcare",
      addRechargeStation: "Adăugare stație de încărcare | Ecohop Partener Stații de încărcare",
      rechargeStation: "Stație de încărcare | Ecohop Partener Stații de încărcare",
      accounts: "Conturi de utilizatori | Ecohop Partener Stații de încărcare",
      addAcount: "Adăugare cont de utilizator | Ecohop Partener Stații de încărcare",
    },
  },
  activeProducts: {
    brand: "Brand",
    category: "Categorie",
    deleteProduct: "Stergere produs",
    deleteProductModalBody: "Ești sigur că vrei să ștergi acest produs?",
    for: "Pentru",
    inStock: "Produs disponibil",
    pageTitle: "Produse active",
    price: "Preț (lei)",
    productDeleted: "Produsul a fost șters!",
    productName: "Denumire produs",
    tableTitle: "Produse Active",
  },
  activeServices: {
    juicerAddedSuccesfully: "Furnizor adăugat cu succes!",
    serviceContracted: "Serviciu contractat cu succes!",
    payConfirmationSuccesfully: "Confirmarea plății a fost făcută cu succes",
    activeServicesEditTitle: "Editare servicii active",
    activeServicesWasSaved: "Serviciul a fost salvat",
    amountAC: "Tarif curent alternativ (AC)",
    amountDC: "Tarif curent continuu (DC)",
    city: "Oraș",
    coveredZone: "Rază de acoperire",
    deleteService: "Ești sigur că vrei să ștergi acest serviciu ?",
    deleteJuicer: "Ești sigur că vrei să ștergi acest furnizor ?",
    deleteServiceModalBody: "Ești sigur că vrei să ștergi acest serviciu?",
    deleteJuicerSuccessToast: "Furnizorul a fost șters cu succes.",
    deleteSuccessToast: "Serviciul a fost șters cu succes.",
    generalInfo: "Informații generale",
    id: "Nr. crt",
    addJuicers: "Adăugare furnizor servicii",
    juicersTitle: "Juicer - contract",
    pageTitle: "Servicii active",
    serviceName: "Denumire serviciu",
    serviceWasSaved: "Serviciul a fost adaugat",
    vehicleType: "Serviciu destinat",
    serviceWasContracted: "Servicul a fost contractat cu succes.",
  },
  add: "Adaugă",
  addElectricityContainer: {
    identity: "Identificator",
    address: "Adresă ",
    capacityContainer: "Capacitate container ",
    chargingPortNr: "Număr porturi de încărcare",
    connectorTypes: "Tip conectori",
    connectorType: "Tip conector",
    editContainer: "Editare container",
    editStation: "Editare stație",
    electricityContainerError: "Containerul nu a fost adaugată.",
    electricityContainerSucces: "Containerul a fost adăugată cu succes.",
    electricityContainerUpdateSucces: "Containerul a fost actualizat cu succes.",
    title: "Adaugă un container nou",
    addStation: "Adaugă stație nouă",
    typeContainer: "Selectati tipul containerului",
    selectConnectorType: "Selectati tipul conectorului",
    selectConnectorStatus: "Selectați statusul conectorului",
    selectStationStatus: "Selectați statusul stației",
    connectorAdded: "Conectorul a fost adăugat",
    connectorUpdated: "Conectorul a fost actualizat",
    connectorDeleted: "Conectorul a fost șters",
    addConnector: "Adaugă conector",
    stationAdded: "Stația a fost adăugată cu succes.",
    stationUpdated: "Stația a fost actualizată cu succes.",
    stationDeleted: "Stația a fost ștearsă cu succes.",
    stationConnectedWithContainer: "Stația este conectată la containerul - ",
    stationNotConnectedWithContainer: "Stația nu este conectată la un container (independentă)",
    connectorModalTitle: "Ștergere conector",
    connectorModalBody: "Ești sigur că vrei să ștergi acest conector?",
  },
  addPrice: {
    pricesSaved: "Tarifele au fost salvate cu succes.",
    pricesUpdated: "Tarifele au fost actualizate cu succes.",
  },
  adminUserRoles: {
    admin: "Administrator",
    partial_admin: "Administartor parțial",
  },
  aiController: {
    aiController: "AI Controller",
    endDate: "Data de sfârșit",
    live: "Live",
    startDate: "Data de început",
    static: "Static",
    train: "Train",
    lastUpdate: "Ultima actualizare:",
  },
  analytics: {
    analyticsTitle: "Date analitice",
    averageTripsDuration: "Durata medie a unei călătorii",
    bikes: "Biciclete",
    cars: "Mașini",
    containers: "Containere",
    earnings: "Încasări",
    endTrip: "Sfârșit cursă",
    general: "General",
    km: "KM",
    maps: "Hărți",
    mediumEarningsperCicle: "Venit mediu general per ciclu de încărcare (LEI)",
    mediumTripPerCicle: "Distanța medie parcursă per ciclu de încărcare (KM)",
    payments: "Plăți",
    scooters: "Trotinete",
    startTrip: "Start cursă",
    totalNumberOfTrips: "Număr total de călătorii",
    totalNumberOfTripsPerHours: "Număr total de călătorii pe intervale orare",
    totalPaymentsApp: "Total plăți din aplicație",
    totalTripsCicle: "Număr mediu de călătorii per ciclu de încărcare",
    totalUnpaid: "Total sume neîncasate",
    totalPaid: "Total sume încasate",
    trips: "Călătorii",
    tripsPerHour: "Călătorii pe oră",
    vehicles: "Vehicule",
  },
  appServices: {
    bikes: "Biciclete electrice",
    cars: "Mașini electrice",
    chooseVehicleType: "Selectează tipul de vehicul",
    contactInfo: "Informații contact",
    scooters: "Trotinete electrice",
    servicesFor: "Servicii pentru",
  },
  auth: {
    401: "User sau parolă greșite. Te rugăm încearcă din nou.",
    accountCreated: "Contul a fost creat cu succes! Vei fi redirecționat către pagina de logare.",
    accountNotCreated: "Procesul de autentificare nu a fost finalizat. Te rugăm încearcă din nou.",
    backToAuth: "Înapoi la autentificare",
    createAccount: "Creare cont",
    expiredToken: "Ne pare rău, link-ul nu mai este valid sau parola a fost deja setată.",
    forgotPassword: "Ai uitat parola?",
    forgotPasswordDescription: "Te rugăm introdu adresa de email asociată contului Ecohop.",
    logInButton: "Autentificare",
    logOutBody: "Ești sigur că vrei să te deconectezi?",
    logOutTitle: "Deconectare",
    notAuthorised: "Ne pare rău, nu ai permisiunea să accesezi această pagină!",
    passwordChangedSuccessfully: "Parola a fost schimbată cu succes.",
    resetPasswordButton: "Resetare parolă",
    resetPasswordDescription:
      "Noua parola a contului tău trebuie să conțină minim 8 caractere (minim 1 literă mare, minim 1 cifră).",
    resetPasswordTitle: "Setare parolă noua",
    setPasswordDescription:
      "Parola contului tău trebuie să conțină minim 8 caractere (minim 1 literă mare, minim 1 cifră).",
    welcomeMessage: "Bine ai venit!",
  },
  cancel: "Renunță",
  connectorType: {
    AC: "AC",
    CCS: "CCS",
    CHAdeMO: "CHAdeMO",
    OUTLET_220: "220",
  },
  create: "Creare",
  dashboard: {
    bikesRidesInProgress: "Călătorii cu bicicleta  în desfășurare",
    noOfElectricityContainers: "Containere de electricitate în uz",
    scootersRidesInProgress: "Călătorii cu trotineta în desfășurare",
    showRoadToVehicle: "Afișează drumul până la vehicul",
    usersInApp: "Utilizatori autentificați în aplicație",
  },
  decline: "Respinge",
  delete: "Ștergere",
  documents: {
    back: "Spate",
    disclaimer:
      "Numele și data expirării sunt extrase automat din fotografiile încărcate de utilizator.<br /><strong>Verifică corectitudinea</strong> acestora inainte de a le introduce în baza de date.",
    document: "Document",
    documentAccepted: "Documentul a fost acceptat cu succes",
    documentDeclined: "Documentul a fost respins cu succes",
    documents: "Documents",
    documentsUpdated: "Informatiile au fost actualizate cu succes",
    driverLicense: "Permis de conducere",
    front: "Fața",
    identityCard: "Carte de identitate",
    processingDocuments: "Documentele sunt procesate...",
    userInfo: "Informații utilizator",
    expiringDate: "Data expirării",
    updatedAt: "Ultima actualizare",
    notApprovedDocuments: "Utilizatori cu documente în revizuire",
    createdAt: "Data încărcării",
    validate: "Validare",
    selectFaceStatus: "Selectează statusul feței documentului",
    selectBackStatus: "Selectează statusul spatelui documentului",
  },
  download: "Descărcare",
  edit: "Editare",
  containersAndStations: {
    addContainer: "Adaugă container",
    addStation: "Adaugă stație",
    bookingHistory: "Istoric rezervări",
    container: "Container",
    containers: "Containere",
    stations: "Stații",
    connectors: "Conectori",
    containereENECactive: "Containere aflate în utilizare",
    numberOfUsedStations: "Stații aflate în utilizare",
    energyContainer: "Container energie",
    containersMap: "Hartă containere",
    stationsMaps: "Hartă stații",
    prices: "Tarife",
    rechargeStation: "Stații de reîncărcare",
    title: "Stații încărcare și containere de energie",
    electricityContainerDeleted: "Container-ul a fost șters cu succes.",
    electricityContainerNotDeleted: "Container-ul nu a fost șters",
    error: "Error",
    id: "ID",
    levelOfBattery: "Nivel de încărcare (%)",
    numberOfDailyCharges: "Număr de încărcări zilnice",
    statusInvertor: "Status invertor",
    pricesUpdated: "Tarifele au fost actualizate",
    remainingEnergyCapacity: "Capacitate energie rămasă",
    status: "Status",
    totalCapacity: "Capacitate totală energie",
    deleteStationModalTitle: "Ștergere stație",
    deleteStationModalBody: "Ești sigur că vrei să ștergi această stație?",
    stationDeleted: "Stația a fost ștearsă cu succes",
    chargingStart: "Start alimentare",
    chargingStop: "Stop alimentare",
    bookDeleted: "Rezervarea a fost ștearsă cu succes",
    bookEnded: "Rezervarea a fost finalizată cu succes",
    deleteBookModalBody: "Ești sigur că vrei să anulezi această rezervare?",
    endBookModalBody: "Ești sigur că vrei să finalizezi această rezervare?",
    startChargingModalBody: "Ești sigur că vrei să pornești alimentarea?",
    stopChargingModalBody: "Ești sigur că vrei să oprești alimentarea?",
    startCharging: "Pornire alimentare",
    stopCharging: "Oprire alimentare",
    deleteBook: "Anulează rezervare/comandă",
    endBook: "Finalizează rezervare/comandă",
    refundCompleted: "Restituirea banilor a fost realizată cu succes",
  },
  electricityContainerStatus: {
    available: "Disponibil",
    charging: "Ocupat",
    outofuse: "Neutilizabil",
  },
  invertorStatus: {
    charging: "Se încarcă",
    discharging: "Se descarcă",
  },
  electricityContainerTypes: {
    fixed: "Fix",
    mobile: "Mobil",
  },
  fields: {
    reasonForDeclining: "Motivul respingerii",
  },
  forgotPassword: {
    401: "Această adresă de e-mail nu este corespondentă unui cont Ecohop.",
    expiredToken: "Ne pare rău, link-ul nu mai este valid sau parola a fost deja resetată.",
    forgotPasswordTitle: "Resetare parolă",
    resetSuccessfully:
      "Te rugăm verifică adresa de mail și accesează link-ul din email pentru a continua procesul de resetare a parolei.",
  },
  formLabels: {
    stationTransportFee: "Taxă transport stație",
    accountCreatedAt: "Dată creare cont",
    activate: "Activează",
    add: "Adaugă",
    addPartner: "Adaugă partener",
    addProduct: "Adaugă produs",
    address: "Adresa",
    addService: "Adaugă serviciu",
    addSupplier: "Adaugă furnizor",
    addUser: "Adaugă utilizator",
    amountAC: "Tarif încărcare curent alternativ (AC)",
    amountBicycle: "Tarif/bicicletă",
    amountBicycleAndScooter: "Tarif/trotinetă sau bicicletă",
    amountCar: "Tarif/mașină",
    amountDC: "Tarif încărcare curent continuu (DC)",
    amountScooter: "Tarif/trotinetă",
    attributtes: "Atribute",
    availability: "Disponibilitate",
    averageTravelTime: "Durată călătorie medie",
    averageTripsPerDay: "Număr mediu de călătorii zilnice",
    batteryLevel: "Nivel baterie",
    currentBatteryLevel: "Nivel actual al baterie",
    block: "Blochează",
    bookType: "Tip rezervare",
    bookID: "ID rezervare",
    bookFee: "Taxă rezervare neonorată",
    categories: "Categorii",
    category: "Categorie",
    characteristics: "Caracteristici",
    chargingAddress: "Adresa încărcare",
    chargingDuration: "Durată alimentării",
    chooseSupplier: "Alege un furnizor",
    CIF: "CIF",
    cifCnp: "CIF/CNP",
    city: "Oraș",
    company: "Nume companie",
    companyName: "Denumire",
    companyType: "Tip Companie (mutiple choice)",
    contact: "Date de contact",
    coveredZone: "Rază de acoperire",
    conectorID: "ID conector",
    currency: "Monedă",
    customId: "ID custom",
    date: "Dată efectuare plată",
    dateOfBook: "Data rezervării",
    description: "Descriere",
    details: "Detalii",
    detailsAboutContainer: "Detalii container",
    detailsAboutPartner: "Detalii partener",
    editService: "Editează serviciu",
    electricVehicleType: "Tip vehicul electric",
    email: "Email",
    expiringDateCI: "Dată expirare CI",
    expiringDateDriverLicense: "Dată expirare permis",
    fastCharge10: "Încărcare rapidă - 10 minute",
    fastCharge20: "Încărcare rapidă - 20 minute",
    fastCharge30: "Încărcare rapidă - 30 minute",
    feeCharged: "Tarif perceput",
    firstName: "Prenume",
    for: "Pentru",
    hardware: "Hardware",
    ID: "ID",
    IDIoT: "ID IoT",
    IDscooter: "ID trotinetă",
    images: "Imagini",
    IMEI: "IMEI",
    juicer: "Juicer",
    kilometerPrice: "Tarif per km",
    lastName: "Nume",
    lastReceivedSignal: "Ultimul semnal primit",
    latitude: "Latitudine",
    longitude: "Longitudine",
    manageVehicles: "Gestionare vehicule",
    minutePrice: "Tarif per minut",
    minutePriceBreak: "Tarif per minut de pauză",
    model: "Model",
    name: "Denumire",
    newPassword: "Parolă nouă",
    newPasswordConfirm: "Confirmare parolă noua",
    numberOfCharges: "Număr încărcări",
    orderFee: "Taxă comandă neonorată",
    panMask: "Card",
    partner: "Partener",
    client: "Client",
    password: "Parolă",
    passwordConfirm: "Confirmare parolă",
    paymentStatus: "Status plată",
    vehicleStatus: "Status vehicul",
    paymentPanMask: "Metodă plată (ultimele 4 cifre card)",
    phone: "Telefon",
    phoneNumber: "Număr de telefon",
    productName: "Nume produs",
    productPrice: "Preț de vânzare",
    productStatus: "Produs disponibil",
    publicTransportVehicle: "Tip Mijloc de Transport",
    publicTransportVehicles: "Tip Mijloc de Transport (multiple choice)",
    qrCode: "Cod QR",
    label: "Label",
    save: "Salvează",
    selectCategory: "Selectează categoria",
    selectSubcategory: "Selectează subcategoria",
    selectedDuration: "Durată selectată",
    sendCommand: "Trimite comanda",
    sendOffer: "Trimite solicitare ofertă",
    serviceName: "Tip serviciu",
    serviceType: "Tip serviciu dorit",
    showContactInfo: "Afișează datele de contact",
    status: "Status",
    walletAvailableSold: "Sold disponibil portofel",
    isBlockedForReservation: "Blocat pentru rezervări neonorate",
    reservationStatus: "Status rezervări",
    startAmountEndAmount: "Plată inițială / Sumă finală",
    startAmount: "Plată inițială",
    endAmount: "Sumă finală",
    diffAmount: "Valoare reconciliere",
    subcategory: "Subcategorie",
    supplier: "Furnizor",
    startDate: "Data / Oră de start",
    userDate: "Interval selectat de utilizator",
    endDate: "Data / Oră de finalizare",
    totalAmountSinceLastCharge: "Suma totală de la ultima încărcare",
    totalDistanceInMSinceLastCharge: "Distanța totală de la ultima încărcare",
    totalPaid: "Sumă plătită",
    totalPayment: "Total de plată",
    totalPending: "Sumă de plată",
    totalSum: "Sumă totală",
    totalSuspended: "Plăți suspendate",
    tripsSinceLastCharge: "Călătorii de la ultima încărcare",
    tripsSinceListCharge: "Număr călătorii de la ultima încărcare",
    TypeIoT: "Tip IoT",
    unit: "Unitate de măsură",
    unitPrice: "Tarif unitate de energie",
    unlockPrice: "Tarif deblocare",
    update: "Actualizați",
    userType: "Tip utilizator",
    vehicleID: "ID vehicul",
    vehicleType: "Tip vehicul (mutiple choice)",
    chargedVehicleType: "Tip vehicul încărcat",
    minutes: "minute",
    trips: "Călătorii",
    generalData: "Date generale",
    accounts: "Conturi",
    reports: "Rapoarte",
    prices: "Tarife",
    power: "Putere",
    stationsStatus: "Stare",
    juicers: "Juiceri",
    type: "Tip",
    connectors: "Conectori",
    container: "Container",
    stations: "Stații",
    payment: "Plată",
    paymentID: "ID plată",
    refund: "Restituire",
    paid: "Am plătit",
    alreadyPaid: "Ai plătit",
    suspended: "Suspendat",
    refunded: "Restituit",
    amount: "Durată sumă plătită",
    contract: "Contractează",
    partnerName: "Denumire partener",
    batteryLevelAtStart: "Nivel de încărcare la preluare",
    batteryLevelAtEnd: "Nivel încărcare baterie la activare",
    startDateJuicer: "Dată preluare",
    endDateJuicer: "Dată reactivare",
    tarif: "Tarif (LEI)",
    juicerName: "Denumire juicer",
    licensePlate: "Număr de înmatriculare",
    maxScooterBatteryLevelToPickUp: "Nivelul maxim al bateriei pentru ridicare",
    minScooterBatteryLevelToChargeTo: "Nivelul minim al bateriei pentru activare",
    internalId: "Id intern in stație",
    totalKwUsed: "Total KW folosiți",
    intervalNotExisting: "Acest tip de rezervare nu are interval",
    locationStatus: "Transmite locația",
  },
  formValidation: {
    amountNegative: "Valori negative nu pot fi introduse.",
    dateFormat: "Formatul trebuie sa fie DD.MM.YYYY",
    emailIsRequired: "Adresa de email este obligatorie",
    endDateBeforeStartDate: "Data de sfarsit trebuie sa fie mai mare decat data de inceput",
    fieldsRequired: "Toate field-urile sunt obligatorii.",
    invalidCIF: "CIF-ul trebuie sa contina doar cifre (minim 2, maxim 10)",
    invalidCIForCNP:
      "CIF-ul trebuie sa contina doar cifre (minim 2, maxim 9), CNP-ul trebuie sa contina 13 cifre",
    invalidEmail: "Adresa de email introdusa nu este valida.",
    invalidExistingPassword: "Adresă de email sau parolă incorectă",
    invalidLatitude:
      "Format invalid pentru latitudine. Introduceți un număr cu minim o zecimală și maxim 10.",
    invalidLongitude:
      "Format invalid pentru longitudine. Introduceți un număr cu minim o zecimală și maxim 10.",
    invalidPassword:
      "Parola trebuie sa contina minim 8 caractere (minim 1 litera mare, minim 1 cifra).",
    invalidPhone: "Numarul de telefon nu este valid.",
    invalidNumber: "Format invalid. Introduceți un număr ",
    maxChars120: "Câmpul trebuie să conțină maxim 120 de caractere.",
    passwordIsRequired: "Parola este obligatorie",
    passwordsNotMatching: "Parolele nu coincid.",
    qrCode: "Codul QR trebuie să fie format din 6 cifre.",
    startDateAfterEndDate: "Data de inceput trebuie sa fie mai mica decat data de inceput",
    wrongFileFormat: "Ați introdus un format de fișier neacceptat.",
    wrongPriceFormat:
      "Formatul prețului nu este corect. Trebuie să introduceți un număr cu maxim 2 zecimale.",
    positiveNumber: "Numărul trebuie să fie mai mare sau egal decat 0.",
  },
  historyServices: {
    contactData: "Date de contact",
    data: "Data",
    description: "Descriere solicitare",
    historyServicesTitle: "Solicitări de oferte primite",
    sendTo: "Transmis către",
    sentBy: "Transmis de către",
    serviceType: "Tip serviciu dorit",
  },
  incidents: {
    adjustAmount: "Ajustează soldul portofelului",
    addAmount: "Adaugă sumă",
    textNotification: "Text notificare",
    cancel: "Anulează",
    walletUpdated: "Soldul portofelului a fost actualizat cu success.",
    negativeBalance: "Introdu o sumă negativă pentru a scădea soldul.",
  },
  info: "Detalii",
  invoices: {
    amount: "Tarif total",
    invoicesTitle: "Facturi",
    notpaid: "Neplătit",
    paid: "Plătit",
    partners: "Parteneri",
    payStatus: "Status plată",
    supplier: "Furnizori de servicii",
    users: "Utilizatori",
    type: "Tip",
    trip: "Călătorie",
    booking: "Rezervare",
    errorMessage: "Factura nu a fost descărcată",
    invoiceDataErrorMessage: "Datele de pe factură sunt incomplete.",
  },
  invoicesSupplier: {
    pageTitle: "Facturi",
  },
  lock: "Blocare",
  marketplace: {
    200: "Produsul a fost adăugat cu succes. Acesta va fi reviziuit de către administrator în cel mai scurt timp.",
    accept: "Accept",
    acceptModal: {
      body: "Esti sigur că vrei să accepți acest produs?",
      title: "Acceptare produs",
    },
    addProduct: "Adaugă produs",
    addToCart: "Adaugă în coș",
    showProduct: "Vizualizează produs",
    availableStatus: "Disponibilitate: în stoc",
    categoriesInfo: "Selectează categoriile și subcategoria produsului",
    commandHasBeenSent: "Comanda a fost finalizată cu succes.",
    completeOrder: "Finalizează comanda",
    decline: "Refuz",
    declineModal: {
      body: "Motivul refuzului",
      title: "Refuzare produs",
    },
    filters: "Filtre",
    hour: "oră",
    imageFormat: "Format acceptat: png, jpg, jpeg, webp, svg",
    inStock: "În stoc",
    kg: "KG",
    l: "litru",
    marketplaceTitle: "Administrare produse",
    myCart: "Coșul meu",
    noProductsInCart: "Niciun produs în coș.",
    noProductsToReview: "Niciun produs nu necesită revizuire",
    orderDate: "Dată comandă",
    orderedProducts: "Produse comandate",
    outOfStock: "Lipsă stoc",
    partners: {
      bikes: "Biciclete",
      cars: "Masini",
      scooters: "Trotinete electrice",
      tires: "Anvelope",
      wheels: "Jante",
    },
    pc: "bucată",
    priceNotSpecified: "Pretul nu este specificat",
    productAccepted: "Produsul a fost acceptat.",
    productAdded: "Produsul a fost adăugat în coș",
    productCharacteristics: "Caracteristici produs",
    productCharacteristicsInfo:
      "Atributele si caracteristicile sunt utilizate pentru filtrarea produselor pe marketplace.",
    productDeclined: "Produsul a fost respins.",
    productDetails: "Detalii produs",
    productRemoved: "Produsul a fost scos din coș",
    productSellBy: "Produs vândut de:",
    productsSellBy: "Produse vândute de:",
    reasonForDeclining: "Motivul respingerii",
    resetFilters: "Resetare filtre",
    searchProduct: "Caută un produs",
    summary: "Sumar comandă",
    total: "Suma totală",
    update200:
      "Produsul a fost actualizat cu succes. Acesta va fi reviziuit de către administrator în cel mai scurt timp.",
  },
  menu: {
    aiController: "Ai controller",
    analyticsData: "Date Analitice",
    electricityContainers: "Stații energie electrică",
    invoices: "Facturi",
    logout: "Deconectare",
    marketplaceProducts: "Administrare produse marketplace",
    marketplaceListing: "Listare produse marketplace",
    personUsers: "Utilizatori persoane fizice",
    publicTransportPartners: "Parteneri transport în comun",
    servicesSuppliers: "Furnizori servicii",
    settings: "Setări",
    transportPartners: "Parteneri transport",
    trips: "Călătorii",
    vehicles: "Vehicule",
    geofencing: "Zonare",
  },
  menuPartners: {
    accounts: "Conturi de utilizatori",
    generalData: "Date generale",
    invoices: "Facturi",
    logout: "Deconectare",
    marketplace: "Marketplace",
    orders: "Istoric comenzi",
    reports: "Rapoarte",
    requestOffer: "Solicită ofertă",
    requestOfferHistory: "Istoric solicitări oferte",
    services: "Servicii",
    vehicles: "Administrare vehicule",
  },
  menuProviders: {
    accounts: "Conturi de utilizatori",
    activeProducts: "Produse active",
    activeServices: "Servicii active",
    decinedProductsMarketplace: "Produse neacceptate în Marketplace",
    invoices: "Facturi",
    logout: "Deconectare",
    requestOfferHistory: "Istoric solicitări oferte",
    requests: "Solicitări de oferte primite",
  },
  no: "Nu",
  noData: "Nu există date",
  noResults: "Niciun rezultat",
  page404Body: "Ne pare rău, pagina căutată nu există sau nu ai permisiunea să o accesezi.",
  page404Title: "Ooops...",
  pagination: {
    of: "din",
    page: "Pagina",
  },
  partners: {
    addPartner: "Adaugă un partener",
    addUserToPartner: "Adaugă un user pentru partenerul",
    accounts: "Conturi de utilizatori",
    createAccount: "Creare cont de utilizator",
    addVehicle: "Adaugă vehicul",
    associatedUserAccounts: "Conturi de utilizatori asociate",
    batteryLevel: "Nivel baterie",
    carChargePrices: "Încărcare rapidă autoturisme",
    cashCard: "cash/card",
    client: "Client",
    clientIdentifier: "Identificatior (id trotinetă/mașină)",
    clientName: "Nume client",
    coordinates: "Coordonate",
    courses: "Curse",
    currentLocation: "Locația curentă",
    dashboard: "Dashboard",
    duration: "Durată",
    earningsMadeInSmarmy: "Încasări realizate în Ecohop",
    ecohopTrips: "Călătorii cu Ecohop",
    edit: "Editeaza",
    editCreateDelete: "Editează | Creeaza user | Șterge partener",
    editVehicle: "Editează vehicul",
    fastChargeBikeAndScooter: "Încărcare rapidă trotinete și biciclete",
    finishHour: "Oră de sfârșit",
    generalInfo: "Informații generale",
    identifier: "Număr de identificare/ înmatriculare",
    income: "Venituri",
    invoiceAmount: "Sumă facturată",
    mainPage: "Pagina principală",
    map: "Hartă",
    modalBodyVehicles: "Ești sigur că vrei să ștergi acest vehicul?",
    modalTitleVehicles: "Ștergere vehicul",
    noShowFee: "Taxe rezervare/comandă neonorate",
    partner: "Partener",
    partnersEditTitle: "Editare partener",
    partnersTitle: "Administrare conturi de partener Ecohop",
    partnersVehicle: "Vehicule partener",
    partnersVehicles: "Vehicule parteneri",
    partnerWasSaved: "Partenerul a fost salvat",
    partnerBatteryLevelsUpdated: "Informațiile au fost actualizate.",
    payments: "Plăți",
    paymentType: "Metoda de plată",
    prices: "Tarife",
    pricesBicycles: "Tarife biciclete",
    pricesCars: "Tarife mașini",
    pricesScooters: "Tarife trotinete",
    reports: "Rapoarte",
    reportsPartners: "Rapoarte parteneri",
    ring: "Sună",
    savingError: "Eroare la salvarea informatiilor",
    startHour: "Oră de start",
    totalTripsNumberPerTime: "Număr total de călătorii pe intervale orare",
    tripID: "ID călătorie",
    tripIDorBookId: "ID călătorie / rezervare",
    reservationID: "ID rezervare",
    trips: "Curse",
    tripsAndReports: "Curse",
    tripStatus: "Status",
    userWasSaved: "Utilizatorul a fost adăugat",
    vehicleDetails: "Detalii vehicul",
    vehicleHasBeenAdded: "Vehiculul a fost adăugat cu succes.",
    vehicleHasBeenDeleted: "Vehiculul a fost șters cu succes.",
    vehicleHasBeenUpdated: "Vehiculul a fost actualizat cu succes.",
    vehicleId: "ID vehicul",
    vehiclePage: "Pagină informații vehicul",
    vehicleStarted: "Vehiculul a fost pornit",
    vehicleStoped: "Vehiculul a fost oprit",
    vehicleLocked: "Vehiculul a fost blocat",
    vehicleUnocked: "Vehiculul a fost deblocat",
    vehicleRestarted: "Vehiculul a fost repornit",
    vehicleType: "Tip vehicul",
    ID: "ID",
    payCharge: "Marchează încărcarea ca plătită",
    chargePaied: "Încărcarea a fost marcată ca plătită!",
    batteryLevelJuicers: "Nivel baterie pentru juiceri",
  },
  payment: "Plata",
  personUsers: {
    accountStatus: "Status cont",
    addDate: "Data adăugării",
    blockAccount: "Blocare cont utilizator",
    blockAccountReason: "Motivul pentru care contul de utilizator a fost blocat este:",
    creditCards: "Carduri",
    default: "Default",
    deleteAccount: "Ștergere cont utilizator",
    deleteUser: "Ești sigur că vrei să ștergi contul utilizatorului",
    documents: "Documente",
    email: "Email",
    endUser: "Utilizator Ecohop",
    firstName: "Prenume",
    incidents: "Incidente",
    lastName: "Nume",
    namePhoneOrEmail: "Nume, Telefon sau Email...",
    noCreditCards: "Niciun card de credit",
    noPreferences: "Nicio preferință setată",
    numberOfTrips: "Număr călătorii",
    payments: "Plăți",
    personUsersTitle: "Utilizatori Ecohop",
    phone: "Telefon",
    preferences: "Preferințe",
    send: "Trimite",
    unknownUser: "Utilizator anonim",
    unlockAccount: "Deblocare cont utilizator",
    unlockUser: "Ești sigur că vrei să deblochezi contul utilizatorului",
    userBlocked: "Utilizator blocat",
    userDeleted: "Utilizatorul a fost șters",
    userNotDeleted: "Utilizatorul nu a putut fi șters",
    userStatusUpdateFailed: "Statusul utilizatorului nu a putut fi modificat",
    userUnlocked: "Utilizator deblocat",
    trips: "Călătorii",
    vouchers: "Vouchere",
    chargeVehicles: " Încărcări vehicule",
  },
  productsSuppliers: {
    addSupplier: "Adaugă un furnizor",
    associatedProductsSupplier: "Produsele furnizorului",
    editCreateuserDeleteProduct: "Editează | Creează user | Șterge produs",
    editCreateuserDeleteSupplier: "Editează | Creează user | Șterge furnizor",
    name: "Denumire",
    productDeleted: "Produsul a fost șters",
    products: "Produse",
    productSupplierCif: "CIF/CNP",
    supplierCity: "Oraș",
    supplierDeleted: "Furnizorul a fost șters",
    supplierEmail: "Email",
    supplierPhone: "Telefon",
    supplierProducts: "Furnizorul de produse",
    supplierWasUpdated: "Furnizorul a fost actualizat",
  },
  productStatus: {
    accepted: "Acceptat",
    declined: "Respins",
    pending: "În așteptare",
  },
  PublicTransportParteners: {
    city: "Oraș",
    companyType: "Tip Companie",
    contactDetails: "Date de contact",
    deleteSuccessToast: "Partenerul de transport public a fost șters cu succes.",
    deleteUnsuccessToast:
      "Ștergerea partenerului de transport public a eșuat. Vă rugăm să încercați din nou.",
    generalInfo: "Informatii generale",
    meansOfTransport: "Mijloace de transport",
    nameTransport: "Denumire Regie de Transport",
    pageTitle: "Parteneri transport în comun",
    publicTransportEditTitle: "Editare parteneri transport in comun",
    publicTransportPartnerWasSaved: "Regia de transport a fost salvată",
    tableTitle: "Administrare regii de transport",
  },
  publicTransportPartners: {
    publicTransportPartnersTitle: "Administrare regii de transport",
  },
  reports: {
    averageAmountBetweenCharges: "Venituri medii între încărcări",
    averageAmountPerVehicle: "Venituri medii per vehicul",
    averageDistnaceBetweenCharges: "Distanța medie între încărcări",
    averageNumberOfBookings: "Numărul mediu de rezervări",
    averageOfNumberOfTripsBetweenCharges: "Numărul mediu de calătorii între încărcări",
    averageRating: "Rating mediu",
    numberOfVehicles: "Numărul total de vehicule",
    total: "Venituri totale realizate în aplicație",
  },
  requestOffer: {
    requestHasBeenSent: "Solicitarea a fost trimisă cu succes către furnizorii de servicii.",
    suppliers: "Furnizorii de servicii",
    title: "Dacă vrei să soliciți o ofertă de servicii, te rugăm sa completezi acest formular",
  },
  required: "Required field",
  save: "Salvează",
  server: {
    400: "Ceva nu a mers bine.",
    401: "Nu ai permisiunea să efectuezi această operațiune.",
    403: "Nu ai permisiunea să efectuezi această operațiune.",
    404: "Conținutul căutat nu a fost găsit!",
    500: "Ceva nu a mers bine. Te rugăm încearcă din nou.",
    dataTooLong: "Câmpul [FIELD] conține prea multe caractere. Te rugăm modifică valoare câmpului!",
    duplicated: " - această valoare este deja înregistrată. Te rugăm modifică valoare câmpului!",
    noServerResponse: "Niciun răspuns de la server. Te rugăm încearcă din nou.",
    serviceExists: "Serviciul este adăugat deja",
  },
  servicesPartners: {
    electricBicycles: "Biciclete electrice",
    electricCars: "Mașini electrice",
    electricScooters: "Trotinete electrice",
    juicers: "Juiceri - contract",
  },
  servicesSuppliers: {
    400: "Există deja un cont cu adresa de mail introdusă",
    addService: "Adaugă serviciu",
    addSupplier: "Adaugă un furnizor",
    addUser: "Adaugă un user",
    associatedServicesSupplier: "Serviciile furnizorului",
    editCreateuserDeleteservice: "Editează | Creează user | Șterge serviciu",
    name: "Denumire",
    products: "Produse",
    save: "Salvează",
    serviceDeleted: "Serviciu a fost șters",
    services: "Servicii",
    serviceSupplierCif: "CIF/CNP",
    serviceSupplierManagement: "Furnizori de servicii",
    serviceSupplierName: "Denumire",
    serviceWasAdded: "Serviciul a fost adăugat",
    serviceWasUpdated: "Serviciul a fost actualizat",
    supplierCity: "Oraș",
    supplierEmail: "Email",
    supplierPay: "Plăți",
    supplierPhone: "Telefon",
    supplierServices: "Furnizorul de servicii",
    supplierServicesProducts: "Servicii/Produse",
    supplierWasAdded:
      "Furnizorul a fost adăugat. Pe adresa de email introdusă a fost trimisă invitația de creare cont furnizor.",
    supplierZoneCovered: "Rază de acoperire",
    vehicleType: "Tip vehicul",
    managePartnerVehicles: "Gestionare vehicule parteneri",
    map: "Hartǎ",
    chargesHistory: "Istoric încărcări",
    activateVehicleModalTitle: "Activare vehicul | QR Code - ",
    activateVehicleModalBody: "Ești sigur că vrei să activezi acest vehicul?",
  },
  servicesTypes: {
    "recharge battery": "Încărcare baterie",
    repair: "Reparații",
    vulcanization: "Vulcanizare",
  },
  settings: {
    200: "Invitația a fost trimisă cu succes.",
    400: "Invitația nu a fost trimisă. Există un user cu adresa de email introdusă.",
    500: "Invitația nu a fost trimisă. Te rugăm încearcă din nou.",
    accountsManagement: "Administreaza conturile",
    addNewSetting: "Adăugare setare nouă",
    createAcoountButton: "Trimite invitatie",
    createNewAccount: "Înregistrează cont",
    createNewAccountTitle: "Înregistrează un cont nou de administrator",
    globalSettings: "Setări globale",
    maintenanceSettings: "Setări Mentenanță",
    modalBody: "Ești sigur că vrei să ștergi acest user?",
    modalBodyElectricityContainer: "Ești sigur că vrei să ștergi acest container?",
    modalTitle: "Ștergere user",
    modalTitleElectricityContainer: "Ștergere container",
    partnerDeleted: "Partener-ul a fost șters cu succes.",
    partnerModalBody: "Ești sigur că vrei să ștergi acest partener?",
    partnerModalTitle: "Ștergere partener",
    partnerNotDeleted: "Partener-ul nu fost șters.",
    serviceModalBody: "Ești sigur că vrei să ștergi acest serviciu?",
    serviceModalTitle: "Ștergere serviciu",
    settingHasBeenAdded: "Setarea a fost adăugată cu succes",
    settingHasBeenUpdated: "Setarea a fost modificată cu succes",
    settings: "Setări",
    supplierDeleted: "Furnizor-ul a fost șters cu succes.",
    supplierModalBody: "Ești sigur că vrei să ștergi acest serviciu?",
    supplierModalTitle: "Ștergere serviciu",
    supplierNotDeleted: "Furnizor-ul nu fost șters.",
    userDeleted: "User-ul a fost șters cu succes.",
    wrongJSONFormat: "Format JSON invalid",
  },
  start: "Start",
  stop: "Închide",
  suppliersAccounts: {
    addUser: "Adaugă utilizator",
    usersAccounts: "Conturi de utilizatori asociate",
  },
  trips: {
    modalHeader: "Ești sigur că vrei să închizi această cursă?",
    partnersTrips: "Calatorii parteneri inregistrati",
    paymentsModalHeader: "Detalii plăți",
    publicTransportTrips: "Calatorii mijloace de transport in comun",
    searchPlaceholder: "Caută după numărul de telefon",
    tripClosedSucessfully: "Cursă închisă cu succes",
    tripRefundedSucessfully: "Cursă restituită cu succes",
    tripsTitle: "Călătorii",
    refundModalHeader: "Ești sigur că vrei să restitui banii acestei curse?",
    refundChargingModalHeader: "Ești sigur că vrei să restitui banii acestei încărcări?",
  },
  tripStatus: {
    ACTIVE: "ACTIVĂ",
    CANCELED: "ANULATĂ",
    FINISHED: "INCHEIATĂ",
    PAUSED: "PAUZĂ",
    RESERVED: "REZERVAT",
  },
  unlock: "Deblocare",
  restart: "Restart",
  userAccountStatus: {
    active: "Activ",
    blocked: "Blocat",
    inactive: "Inactiv",
    unverified: "Invitație trimisă",
  },
  userRoles: {
    admin: "Administrator",
    normal: "Normal",
    partial_admin: "Administrator parțial",
    partner: "Partener",
    partner_admin: "Partener - Admin",
    supplier: "Furnizor",
    supplier_admin: "Furnizor - Admin",
    charging_station_partner_admin: "Partener stații încărcare",
  },
  vehicleStatus: {
    active: "Activ",
    charging: "În curs de încărcare",
    damaged: "În service",
    in_use: "În mișcare",
    inactive: "Inactiv",
    reserved: "Rezervat",
    stolen: "Furat",
    unavailable: "Indispobibil",
    location_unavailable: "Locație indisponibilă",
  },
  vehicleTypes: {
    scooter_or_bicycle: "Trotinetă  sau Bicicletă",
    bicycle: "Bicicletă electrică",
    bus: "Autobuz",
    "bus-tram": "Autobuz și tramvai",
    car: "Mașină",
    publicTransport: "Transport public",
    publicTransportStations: "Stații transport public",
    rideSharing: "Ride Sharing",
    scooter: "Trotinetă electrică",
    tram: "Tramvai",
    vehicles: "Vehicule",
    "N/A": "N/A",
  },
  vouchers: {
    promoCode: "Cod promoțional",
    promoType: "Tip promoție",
    minutesType: "minute gratuite",
    couponType: "valoare cupon",
    used: "Utilizat",
    expiryDate: "Dată expirare",
    status: "Status",
    active: "Activ",
    expired: "Expirat",
    loading: "Se încarcă",
    freeUnlock: "Deblocare gratuită",
  },
  yes: "Da",
  areYouSure: "Ești sigur?",
  stationsStatus: {
    active: "Activă (active)",
    outofuse: "Scoasă din funcțiune (outofuse)",
    maintenance: "În mentenanță (maintenance)",
  },
  connectorStatus: {
    charging: "Încarcă (charging)",
    outofuse: "Scoz din uz (outofuse)",
    available: "Disponibil (available)",
    active: "Activ (active)",
    finished: "Complet (finished)",
    canceled: "Anulat (canceled)",
    in_use: "Activ (in_use)",
  },
  documentStatus: {
    pending: "În așteptare",
    accepted: "Acceptat",
    rejected: "Respins",
    expired: "Expirat",
  },
  documentImageStatus: {
    accepted: "Acceptat",
    rejected: "Respins",
  },
  chargeStatus: {
    pending: "În așteptare",
    paid: "Platit",
    suspended: "Suspendat",
  },
  pay: "Platește",
  payments: {
    details: "Detalii plăți",
    paymentRefundedSucessfully: "Plată restituită cu succes",
    refundModalHeader: "Ești sigur că vrei să restitui această sumă?",
  },
  bookingType: {
    fixed: "Rezervare",
    mobile: "Comandă",
    fastCharge: "Încărcare rapidă",
  },
  bookingStatus: {
    charging: "Încarcă",
    outofuse: "outofuse",
    available: "Disponibilă",
    active: "Activă",
    finished: "Finalizată",
    canceled: "Anulată",
    unfulfilled: "Neonorată",
  },
};
export const NO_VALUE_SYMBOL = "-";
export default GlobalStrings;
