import { Currency, PaymentStatus } from "utils/enums";
import { NO_VALUE_SYMBOL } from "utils/globalStrings";

import { apiSlice } from "../apiSlice";

export interface I_PAYMENT {
  id: number;
  createdAt: string;
  userId: number;
  vehicleId: number;
  containerBookingId: number;
  tripId: number;
  amount: string;
  currency: Currency;
  status: PaymentStatus;
  panMask: string;
  orderId: string;
}

export const paymentsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPayments: builder.query<{ meta: any; payments: I_PAYMENT[] }, any>({
      query: ({ pageIndex, pageSize, sort, userId }) => {
        let url = `${process.env.REACT_APP_PAYMENT_API_URL}/payments/user-history?`;
        if (pageIndex >= 0 && pageSize) {
          url += `pagination=true&page=${pageIndex + 1}&limit=${pageSize}&`;
        }
        if (sort) {
          url += `sort_direction=${sort.sort_direction}&sort_order=${sort.sort_order}&`;
        }
        if (userId) {
          url += `userId=${userId}&`;
        }
        if (url.slice(-1) === "&") {
          url = url.slice(0, -1);
        }
        return url;
      },
      transformResponse: (response: any) => {
        const meta = {
          page: +response?.data?.page,
          numberOfPages: response?.data?.numberOfPages,
          pageSize: +response?.data?.pageSize,
          totalRows: +response?.data?.totalRows,
        };
        const payments: I_PAYMENT[] = [];
        response?.data?.pageData?.forEach((item: any) => {
          payments.push({
            id: item.id,
            createdAt: item?.createdAt || NO_VALUE_SYMBOL,
            userId: item?.userId || NO_VALUE_SYMBOL,
            vehicleId: item?.vehicleId || NO_VALUE_SYMBOL,
            containerBookingId: item?.containerBookingId || NO_VALUE_SYMBOL,
            tripId: item?.tripId || NO_VALUE_SYMBOL,
            amount: item?.amount || NO_VALUE_SYMBOL,
            currency: item?.currency || NO_VALUE_SYMBOL,
            status: item?.status || NO_VALUE_SYMBOL,
            panMask: item?.panMask || NO_VALUE_SYMBOL,
            orderId: item?.orderId || NO_VALUE_SYMBOL,
          });
        });
        return {
          payments,
          meta,
        };
      },
    }),
    refundPayment: builder.mutation<any, any>({
      query: ({ orderId, amount }) => ({
        url: `${process.env.REACT_APP_PAYMENT_API_URL}/netopia-api/refund-admin`,
        method: "POST",
        body: {
          orderId,
          amount,
        },
      }),
    }),
  }),
});

export const { useGetPaymentsQuery, useRefundPaymentMutation } = paymentsApiSlice;
